import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrMoncadaLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Moncada"
    institution="Dr. Moncada"
    referralCode="RITUAL"
    physicianURL="http://www.ritualacupuncture.com/about-joel"
  />
)

export default DrMoncadaLandingPage
